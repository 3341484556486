.card-header {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    // border: 2px solid red;
    background-color: transparent;

    &__title {
        font-family: 'Poppins';
        font-weight: 600;
        font-size: 40px;
        color: $white;
    }


    &__container-alineation {
        width: max-content;
        display: flex;
        align-items: center;
        gap: 1rem;
        margin-top: 1rem !important;
        text-align: left;
        justify-content: center;
        margin: auto;
    }

    &__weather {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        color: $white;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        width: 70px;
    }

    &__descriptions {
        font-family: 'Montserrat' !important;
        font-style: normal;
        font-size: 18px;
        color: $white;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        margin-block: -20px;
        width: 250px;
        white-space: nowrap !important;
        text-overflow: ellipsis !important;
        overflow: hidden !important;
    }

    &__strong {
        font-family: 'Montserrat' !important;

    }

    &__descriptions-estado {
        max-width: 100px !important;
        font-family: 'Montserrat' !important;
        font-weight: 100;
        font-size: 30px !important;
    }

    &__descriptions-weather {
        font-family: 'Montserrat';
        font-style: normal;
        font-size: 25px;
        color: $white;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        margin-block: -27px;
        width: max-content;
        overflow-wrap: break-word;
    }

    &__subtitle {
        display: flex;
        justify-content: center;
        margin: auto;
        font-size: 24px;
        font-weight: bold;
        margin-top: 0.5rem;
    }

    &__container-weather {
        display: flex;
        margin: auto;
        align-items: center;

        img {
            width: 4rem;
        }

    }

    &__container-des {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
    }
}

@include for-desktop-up {
    .card-header {
        &__weather {
            font-size: 20px;
        }

        &__subtitle {
            margin-top: 0px !important;
        }
    }
}

@include for-phone-small-only {

    .card-header {
        &__container-weather {
            display: flex;
            margin: auto;
            align-items: center;

            img {
                width: 2rem;
            }
        }

        &__weather {
            font-size: 15px;
        }

        &__container-alineation {
            display: flex;
            flex-direction: column;
            margin-top: 0 !important;
            gap: .5rem !important;
        }

        &__descriptions {
            font-size: 14px;
        }

        &__descriptions-weather {
            font-size: 19px;
        }
    }
}

@include for-15-pulgadas {
    .card-header {
        &__container-alineation {
            margin-top: 0 !important;
        }

        &__title {
            margin-top: -1.5rem !important;
        }
    }
}

@include for-tablet-portrait-up {

    .card-header {

        &__subtitle {
            margin-top: 4rem !important;
            margin-bottom: 0px;
        }
    }
}

@include for-desktop-mac-up {
    .card-header {
        &__title {
            font-size: 30px;
        }
    }
}

@include for-phone-small-only {
    .card-header {
        &__title {
            font-size: 20px;
        }
    }
}