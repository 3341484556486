.arrow-carrousel {
    color: $primaryColor-Green;
    font-weight: bolder;
    transform: scale(1.3);
}

@include for-tablet-portrait-up {
    .arrow-carrousel {
        color: white;
        font-weight: bolder;
        transform: scale(1);
    }
}