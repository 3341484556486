//General colors
$primaryColor-Green: #306A59;
$secondaryColor-gray: #B4B4B4;
$accent-gray: #D9D9D9;
$accent-green: rgba(48, 106, 89, 0.2);
$accent-black: #222222;
$green-dark: #5B5B5B;


//delete
$delete-red: #FB3232;

//bases
$white: #FFFFFF;
$black: #000000;

//cards

$red-card: #e4032e;
$green-card: #7ab929;
$yellow-card: #f49514;

@mixin fontSize {
    font-family: 'Montserrat';
    font-size: 22px;
    height: max-content;
}