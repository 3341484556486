.container-title {
    p {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 50px;
        color: $primaryColor-Green;
    }

    &__subrayado {
        color: $primaryColor-Green;
        border: 2px solid $primaryColor-Green;
        width: 4rem;
        margin-top: -2.2rem;
    }
}

@include for-tablet-portrait-up {
    .container-title {
        margin: auto;

        p {
            font-weight: 700;
            font-size: 25px;
            line-height: 50px;
            color: $primaryColor-Green;
        }

        &__subrayado {
            color: $primaryColor-Green;
            border: 2px solid $primaryColor-Green;
            width: 4rem;
            margin-top: -2.2rem;
        }
    }
}