.container-page {
    display: flex;
    flex-direction: column;
    padding-bottom: 5rem;
    padding-inline: 5rem;

    &__id {
        color: $primaryColor-Green;
        font-size: 15px;
    }

    &__card-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    &__login {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 5rem;
    }


    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-inline: 2rem;
        margin-bottom: 2rem;
    }

    &__description {
        color: $primaryColor-Green;
        font-size: 15px;
        width: 35rem;
        display: flex;
        align-items: center;
    }

    &__cards {
        margin: auto;
    }

    &__button-new {
        display: flex;
        align-self: flex-end;
        margin: 2rem;
        justify-content: flex-end;
    }

    &__center {
        display: flex;
        margin: auto;
        justify-content: center;
    }

    &__center-buttons {
        gap: 1rem;
        display: flex;
        margin: auto;
        justify-content: center;
    }

    &__pagination {
        display: flex;
        margin: auto;
        justify-content: center;
        margin-top: 1rem;
    }

    &__subtitle {
        color: $primaryColor-Green;
        font-size: 32px;
        font-weight: 900;
        text-align: center;
    }

    &__table {
        overflow-x: auto;

    }

    &__carousel {
        margin-inline: 3rem;
    }


    &__modules {
        display: flex;
        flex-direction: column;
        margin: auto;
        justify-content: center;
    }

}

.button-order-perfil {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
}

@include for-phone-only {

    .container-page {
        padding-inline: 2.5rem;

        &__center-buttons {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__id {
            color: $primaryColor-Green;
            font-size: 15px;
        }

        &__header {
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 1rem;
        }

        &__card-center {
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
                font-size: 15px;
                font-family: "Inter", sans-serif;
            }
        }
    }

    .card-info-home {

        &__subtitle {
            margin-top: 4rem !important;
            margin-bottom: 0px;
        }

        &__sensorHeigth {
            margin-top: -4rem !important;
        }

        &__info {
            margin-top: 2rem !important;
        }
    }

    // FLIP 4, INTERFIERE CON WEB DE IPHONE ?


    .ant-progress-inner {
        width: 130px !important;
        height: 130px !important;
    }

    .custom-arrow {
        z-index: 1000 !important;
        top: 1% !important;
    }

    .card-body {
        margin-top: -2rem !important;
    }

}

@include for-phone-small-only {

    .container-page {
        padding-inline: 1rem;
        padding-bottom: 1rem;

        &__id {
            color: $primaryColor-Green;
            font-size: 15px;
        }


        &__card-center {
            margin: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            p {
                font-size: 25px;
                font-family: "Inter", sans-serif;
            }
        }

        &__cards {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
        }

        &__header {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-inline: 1rem;

        }

        &__description {
            color: $primaryColor-Green;
            font-size: 15px;
            text-align: center;
            display: flex;
            justify-content: center;
            max-width: 15rem;
        }

        &__button-new {
            display: flex;
            align-self: center;
            margin: .5rem;
        }

        &__table {
            overflow-x: auto;
            margin-inline: 0;
            max-width: 100%;
        }


        &__carousel {
            margin-inline: 4rem;

        }

        &__login {
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding-top: 0;
        }


    }

    .custom-arrow {
        z-index: 1000 !important;
        top: 1% !important;
    }
}