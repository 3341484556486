.chart {
    margin-bottom: 2rem;

    &__title {
        color: $primaryColor-Green;
        font-weight: bold;
        font-size: 30px;
    }

    &__id {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        color: $accent-black;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
        margin-top: -2rem;
    }

    &__graph {
        display: grid;
        margin: auto;
        grid-template-columns: repeat(2, 600px);
        grid-gap: 2rem;
        justify-content: center;
        margin-bottom: 5rem;
        align-items: center;
    }
}

@include for-tablet-portrait-up {
    .chart {
        &__graph {
            display: flex;
            flex-direction: column;
            margin: auto;
            align-items: center;
            justify-content: center;
        }

        &__id {
            margin-top: -2rem;
        }
    }
}