.footerHome {
    background: linear-gradient(to right, #00A0A0, #009FCB);
    display: flex;
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 4rem;
    justify-content: center;

    p{
        color: white;
        font-size: 24px;
        font-weight: 400;
        font-family: "Roboto";
    }
}



.ant-layout-header {
    display: flex;
    background-image: url(../../../img/backgroundHome.png);
    justify-content: space-between !important;
    align-items: center;
    background-size: 100% 356rem;
    padding-inline: 15px;
    background-repeat: no-repeat;
    height: 15%;


    .logo {
        font-weight: bold;
        font-size: 20px;

    }

    .ant-menu-horizontal {
        background-color: transparent;
        width: 100%;
        border: 2px solid white;

    }


}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item:hover::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu:hover::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-item-active::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-active::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-item-open::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-open::after {
    border: 2px solid white;
    color: $white;
}

.ant-menu-light .ant-menu-item a:hover {
    color: white;
    font-weight: bold;

}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected::after {
    border: none;
    border: 2px solid white;

}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected {
    color: $white;
    border: none;

    font-weight: bold;
}

.site-layout-content {
    min-height: 100vh !important;
    height: 100% !important;
    background-color: whitesmoke;
    padding: 0%;
}

.ant-menu-light .ant-menu-item-selected {
    color: $white;
    font-weight: bold;
    border: none;

}

.ant-menu-light .ant-menu-item-selected {
    border: none;

}

.ant-layout-header .ant-menu-horizontal {
    width: 100%;
    border: none;
    color: $white;
    display: flex;
}

@include for-phone-only {

    .ant-layout .ant-layout-header {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;


        .ant-menu-horizontal {
            padding-left: 1rem;
            width: 25rem;
            display: none;
            justify-content: flex-start;
        }

        button {
            margin-top: 1rem;

        }

    }

    .menu-user {
        margin-inline: 1rem !important;

        button:last-of-type {
            margin-left: auto !important;
        }
    }
}

.error-modal {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 1rem;

    a {
        color: $primaryColor-Green;
    }
}

.modal-notificaciones {
    display: flex;
    flex-direction: column;

    p {
        text-align: center;
        font-weight: bold;
        font-size: 20px;
    }

    &__imagen {
        display: flex;
        margin: auto;
        justify-content: center;
        align-items: center;
        gap: 2px;
    }
}

.ant-drawer-body {
    ul {
        .ant-menu-item-selected {
            background-color: $primaryColor-Green;
        }

        .ant-menu-item:not(.ant-menu-item-selected):hover {
            background-color: $green-dark !important;
            color: black !important;
        }
    }

}

.ant-drawer-content-wrapper {
    width: 100% !important;
}