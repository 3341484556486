@include for-tablet {
    .menu-desktop {
        &__nav {
            ul {
                li {
                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .card-info-home {

        &__subtitle {
            font-size: 20px;
        }

        &__module {
            p {
                font-size: 15px;
            }
        }

        &__info {
            margin-top: 0px;
        }
    }

    .card-home-desktop {
        height: 27rem;
        width: 380px;

        &__modulos {
            margin-top: -1rem !important;
        }
    }

    .slick-grid {
        margin-top: 0px;
    }

    .ant-progress-text {
        font-size: 20px !important;
    }

    .ant-progress-inner {
        width: 100px !important;
        height: 100px !important;
    }
}

@include for-tablet-portrait-up {
    .container-card {
        width: 22rem !important;
    }

    .container-carousel {
        width: 100% !important;
        height: 100% !important;
    }

    .slick-next,
    .slick-prev {
        top: 15% !important;
    }

    .slick-next::before,
    .slick-prev::before {
        color: $primaryColor-Green !important;
    }


    .custom-arrow {
        z-index: 1000 !important;
        top: 10% !important;
    }



}




@include for-tablet-portrait-up {

    .card-info-home {

        &__subtitle {
            margin-top: 4rem !important;
            margin-bottom: 0px;
        }
    }
    .ant-progress-inner {
        width: 95px !important;
        height: 95px !important;
    }

    .container-principal {
        background-size: 100% 15rem;
    }

}
