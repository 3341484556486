@mixin for-phone-small-only {
  @media (max-width: 300px) {
    @content;
  }
}

@mixin for-phone-only {
  @media (max-width: 485px) {
    @content;
  }
}

@mixin for-iphone {
  @media (max-width: 680px) {
    @content;
  }
}


@mixin for-tablet-portrait-up {
  @media (max-width: 800px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (max-width: 900px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}

@mixin for-desktop-mac-up {
  @media (max-width: 1280px) {
    @content;
  }
}

@mixin for-1366 {
  @media (max-width: 1370px) and (min-width: 1360px) and (max-height: 770px) {
    @content;
  }
}

@mixin for-15-pulgadas {
  @media (max-width: 1370px) and (min-width: 1360px) and (max-height: 670px) and (min-height: 650px) {
    @content;
  }
}

@mixin for-tablet {
  @media (max-width: 1286px) and (max-height: 656px) {
    @content;
  }
}


@mixin for-1920 {
  @media (min-width: 1910px) and (max-width: 1950px) and (min-height: 740px) and (max-height: 760px) {
    @content;
  }
}

@mixin for-1440 {
  @media (max-width: 1450px) and (max-height: 680px) {
    @content;
  }
}

@mixin for-14 {
  @media (min-width: 1200px) and (max-width: 1300px) and (min-height: 500px) and (max-height: 600px) {
    @content;
  }
}