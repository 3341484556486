.spinner-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.spinner {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 8px solid $secondaryColor-gray;
    /* color verde oscuro */
    border-top-color: transparent;
    animation: spin 0.8s linear infinite;
}

.spinner-inner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 4rem !important;
}

.spinner-inner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 8px solid $secondaryColor-gray;
    /* color verde oscuro */
    border-top-color: transparent;
    animation: spin 0.8s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}