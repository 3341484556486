.container-card {
    width: 25rem !important;
    display: flex;
    flex-direction: column;
}

.container-principal {
    width: 100% !important;
    height: 100% !important;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-image: url(../../../img/backgroundHome.png);
    background-size: 100% 18rem;
    background-repeat: no-repeat;
}

.container-carousel {
    width: 90% !important;
    margin: auto;
    background-color: transparent;
}

.home-desktop {
    margin-top: 2rem;
}

.card-home-desktop {
    background-color: white;
    display: flex !important;
    text-align: center;
    flex-direction: column !important;
    padding-top: 2rem !important;
    background-image: url(../../../img/backgroundHome.png);
    background-size: 100% 19rem;
    background-repeat: no-repeat;
    border-radius: 1.9rem !important;
    box-shadow: 12px 12px 28px -20px rgba(0, 0, 0, 0.5);
    width: 25rem;
    height: 40rem;

    &__modulos {
        margin-top: 2rem !important;

    }

    &__footer {
        width: 100%;
        background: linear-gradient(to right, #00A0A0, #009FCB);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 3rem;
        border-radius: 0 0 1.9rem 1.9rem !important;

    }
}

.parametros {
    margin-top: -2rem !important;
}

@include for-desktop-mac-up {
    .card-home-desktop {
        padding-top: .5rem;
        background-size: 100% 15rem;
        width: 23rem;
        height: 33rem;

        &__footer {
            width: 100%;
            background-color: #50B6B8;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 3rem;
            border-radius: 0 0 1.9rem 1.9rem !important;
        }

        &__modulos {
            margin-top: 1rem !important;
        }
    }

    .parametros {
        margin-top: -2.8rem !important;
    }

    .card-info-home {

        &__info {
            margin-top: 0 !important;
        }
    }
}

@include for-1440 {

    .menu-desktop {
        &__nav {
            ul {
                li {
                    span {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .card-info-home {
        &__subtitle {
            font-size: 20px;
        }

        &__module {
            p {
                font-size: 15px;
            }
        }

        &__info {
            margin-top: 0px;
        }
    }


    .card-home-desktop {
        height: 32rem;
        background-size: 100% 17rem;
        width: 360px;

        &__modulos {
            margin-top: 0px !important;
        }
    }

    .slick-grid {
        margin-top: 0px;
    }
    .ant-progress-inner {
        width: 70px !important;
        height: 70px !important;
    }

    .ant-progress-text {
        font-size: 12px !important;
    }

    .ant-progress-text-notValue {
        font-size: 12px !important;
    }
}

@include for-1366 {
    .card-home-desktop {
        height: 36rem !important;
        width: 360px;
    }

    .card-info-home__parametersHeigth {
        margin-top: 1rem;
    }

    .card-home-desktop {
        background-size: 100% 13rem !important;
    }


    .ant-layout-header {
        height: 13% !important;
    }
}

@include for-1920 {

    .card-info-home {
        &__subtitle {
            font-size: 20px;
        }

        &__module {
            p {
                font-size: 15px;
            }
        }

        &__info {
            margin-top: 0px;

            p {
                font-size: 10px;
            }
        }
    }

    .card-home-desktop {
        height: 32rem;

        &__modulos {
            margin-top: 2rem !important;
        }
    }

    .slick-grid {
        margin-top: 0px;
    }


    .ant-progress-inner {
        width: 75px !important;
        height: 75px !important;
    }

    .ant-progress-text {
        font-size: 10px;
    }
}

@include for-14 {

    .card-info-home {
        &__subtitle {
            font-size: 20px;
        }

        &__module {
            p {
                font-size: 15px;
            }
        }

        &__info {
            margin-top: 0px;

            p {
                font-size: 10px;
            }
        }
    }

    .card-home-desktop {
        height: 27.5rem;
        background-size: 100% 13rem;

        &__modulos {
            margin-top: 0rem !important;
        }
    }

    .slick-grid {
        margin-top: 0px;
    }

    .ant-progress-inner {
        width: 65px !important;
        height: 65px !important;
    }

    .ant-progress-text {
        font-size: 20px !important;
    }

    // .card-home-desktop {
    //     background-size: 100% 13rem;

    //     &__modulos {
    //         margin-top: 0px !important;
    //     }
    // }
}

@include for-15-pulgadas {
    .card-home-desktop {
        height: 32rem !important;
        width: 370px !important;

    }
    .card-info-home__parametersHeigth {
        margin-top: 1rem;
    }

    .card-home-desktop {
        background-size: 100% 15rem;
    }

    .ant-layout-header {
        height: 13% !important;
    }

    .ant-progress-inner {
        width: 80px !important;
        height: 80px !important;
    }
}