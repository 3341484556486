.card-info-home {
    position: absolute;
    top: 0;
    width: 35rem !important;
    border: none;
    background-color: transparent;
    position: relative;
    height: calc(100vh - 64px) !important;

    &__subtitle{
       margin-inline: auto;
       text-align: center;
    }

    &__button-nav {
        position: absolute;

        button {
            top: 2rem;
            left: 1rem;
            background-color: transparent;
            border: none;
            box-shadow: none;
        }
    }

    &__header {
        display: flex;
        flex-direction: column;
        gap: -10px;
        position: absolute;
        top: 3rem;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
        width: 90%;
        flex: 1;
    }





    &__body {
        position: absolute;
        top: 15rem;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
        width: 16rem;
        bottom: 0;
        flex: 1;
    }

    &__module {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: flex-start;
        gap: 0px;

        p {
            color: $black;
            font-size: 20px;
            font-weight: bold;
            margin: 0;
        }

        flex: 1;

    }

    &__body-grid {
        display: grid;
        margin: auto;
        grid-template-columns: repeat(auto-fit, 5.8rem);
        grid-gap: 10px;
        max-width: 15rem !important;
        justify-content: center;
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.2rem;
        margin-left: 5rem;
        margin-top: 4rem;

        p {
            color: $black;
            font-size: 15px;
            margin: 0;

            span {
                font-weight: bold;
            }
        }
    }

    &__button-reporte {
        background-color: transparent;
        display: flex;
        position: absolute !important;
        bottom: 0;
        height: 4rem;
        justify-content: flex-start !important;
        align-items: center !important;
        gap: 1rem;
        padding-left: 2rem;
        width: 100%;
        flex: 1;


        button {
            font-size: 20px;
            width: 30px !important;
            height: 3rem !important;
            color: $white;

            &:hover {
                background-color: transparent !important;
                color: $accent-green;
            }
        }
    }

    &__button-module {
        width: 100%;
        display: flex;
        justify-content: space-between;
        position: absolute;
        align-items: center !important;
        bottom: 0;
        height: 4rem;

        button {
            font-size: 20px;
            width: 30px !important;
            height: 3rem !important;
            color: $white;

            &:hover {
                background-color: transparent !important;
                color: $accent-green;
            }
        }
    }

    &__wifi {
        font-size: 25px;
        display: flex;
        color: $white;
    }

    &__button-alert {
        display: flex;
        margin-inline: .2rem;
    }

    &__establishment {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center !important;
        text-align: center !important;
        margin: auto;
        color: $primaryColor-Green;
        font-size: 20px;
        font-weight: bold;

        &:hover {
            color: $green-dark;

        }
    }



}

@include for-desktop-up {
    .card-info-home {
        width: 20rem !important;


        &__body {
            position: absolute;
            text-align: center;
            left: 50%;
            transform: translateX(-50%);
            width: 16rem;
        }

        &__parametersHeigth {
            margin-top: -2rem;
        }

        &__sensors {
            margin-top: 2rem !important;

        }

        &__info {
            margin-top: 5rem !important;
        }


    }
}

@include for-tablet-portrait-up {
    .card-info-home {
        width: 18rem !important;
        border-radius: 30px;

        &__info {
            margin-top: 5rem !important;
        }

        &__sensor-mobile {
            padding-top: .5rem !important;
        }

        &__title {
            font-size: 24px;
        }

        &__subtitle {
            margin-bottom: 2rem;
        }

        &__body {
            position: absolute;
            top: 10rem;
            z-index: 999999999;
        }

        &__button-reporte {
            background-color: red;
            display: flex;
            position: absolute !important;
            bottom: 64px;
            height: 4rem;
            justify-content: flex-start !important;
            align-items: center !important;
            gap: 1rem;
            padding-left: 2rem;
            width: 100%;
            flex: 1;


            button {
                font-size: 20px;
                width: 30px !important;
                height: 3rem !important;
                color: $white;

                &:hover {
                    background-color: transparent !important;
                    color: $accent-green;
                }
            }
        }

        &__sensors {
            margin-top: 2rem !important;

        }

        .slick-next::before,
        .slick-prev::before {
            color: black !important;
            top: 50% !important;
        }

        .slick-next,
        .slick-prev {
            top: 50% !important;
        }
    }
}

@include for-phone-small-only {

    .card-info-home {

        &__info {
            margin-top: 5rem !important;
        }


        &__sensor-mobile {
            padding-top: 2.5rem !important;
        }


        &__container-alineation {
            display: flex;
            flex-direction: column;
            margin-top: 0 !important;
            gap: .5rem !important;
        }

        &__descriptions {
            font-size: 14px;
        }

        &__descriptions-weather {
            font-size: 19px;
        }
    }
}

@include for-desktop-mac-up {
    .card-info-home {

        &__parametersHeigth {
            margin-top: 1rem;
        }

        &__subtitle {
            margin-top: 1rem !important;
        }

        &__info {
            margin-top: 1rem !important;
        }

        &__module {
            p {
                font-size: 15px;
            }
        }
    }
}