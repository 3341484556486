.card-info {
    width: 20rem !important;

    &__establishment {
        margin: auto;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $black;
        font-size: 20px;
        font-weight: bold;
        text-align: left;

        img {
            position: absolute;
            left: 20px;
        }

        &:hover {
            color: $green-dark;

        }
    }

}

.ant-modal-content {
    width: 600px !important;
}

.container-varieties {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
    margin-top: 2rem;
    align-items: center;
    width: 100%;

    &__rows {
        width: 50%;
    }
}

.form-variedades {
    border: 1px solid rgb(183, 181, 181);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
    border-radius: 20px;

    p {
        font-size: 20px;
    }
}

.line-variedades {
    margin-bottom: 10px;
    border: 1px solid rgb(183, 181, 181);
    width: 100%;
}

.row-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.ant-col-16 {
    padding-right: 0px !important;
}

.col-8-edit {
    padding-right: 0px !important;
}

.hola {
    width: 300px;
    display: flex !important;
}

.switch-direction {
    display: flex;
    width: 300px;
    gap: 1rem;

    p {
        margin-top: 10px;
    }
}

.ant-space {
    display: flex;
    align-items: center;
}

@include for-desktop-up {

    .container-varieties {
        display: flex;
        flex-direction: column;

        &__rows {
            width: 100% !important;
        }

        &__button {
            margin-top: 2rem;
        }
    }

    .ant-modal-content {
        width: 100% !important;
    }

    .modal-celphone {
        margin-top: 7.5rem !important;

        .ant-modal-content {
            height: 50vh;
        }
    }

    .ant-modal-title {
        font-size: 24px !important;
    }

    .ant-modal-body {
        font-size: 22px !important;
    }

    .card-info {
        border: 4px solid rgba(0, 0, 0, 0.1);
        width: 20rem !important;
        border-radius: 30px;
    }


}

@include for-tablet-portrait-up {

    .modal-celphone {
        margin-top: 7.5rem !important;
    }

    .ant-modal-title {
        font-size: 24px !important;
    }

    .ant-modal-body {
        font-size: 22px !important;
    }

    .card-info {
        border: 4px solid rgba(0, 0, 0, 0.1);
        width: 18rem !important;
        border-radius: 30px;
    }

    .ant-card {
        margin-bottom: 1rem;
    }

}

@include for-phone-small-only {

    
    .modal-celphone {
        margin-top: 7.5rem !important;
    }


    .ant-modal-title {
        font-size: 30px !important;
    }

    .ant-modal-body {
        font-size: 20px !important;
    }

}

@include for-desktop-mac-up {
    .ant-progress-text {
        color: $black !important;
        font-size: 15px;
        font-weight: bold;
        margin-top: -.5rem !important;

    }


    .ant-progress-text-notValue {
        color: $black !important;
        font-size: 12px;
        font-weight: bold;
        height: 2rem;

    }
}