.button-icon {
    background-color: transparent !important;
    border: none;
    color: $white;
    box-shadow: none !important;
    width: 20px !important;

    &:disabled {
        color: black;
    }

    img {
        filter: invert(1);
        width: 20px !important;
    }

}

.button-transparent {
    background-color: transparent;
    border: none;
    color: $primaryColor-Green;
    box-shadow: none !important;
    @include fontSize;


    &:hover {
        background-color: transparent !important;
        border: none !important;
        color: $accent-black !important;
    }

    &:active {
        background-color: transparent !important;
        border: none !important;
        color: $accent-black !important;
    }

    &:focus-visible {
        outline: 4px solid $accent-green !important;
    }

}

.button-transparent-all {
    background-color: transparent;
    border: none;
    color: white !important;
    box-shadow: none !important;
    @include fontSize;

    &:hover {
        background-color: transparent !important;
        color: $white !important;
    }

    &:active {
        background-color: transparent !important;
        color: $white !important;
    }

    &:disabled {
        background-color: transparent !important;
        color: $black !important;
    }

    &:focus-visible {
        outline: 4px solid transparent !important;
    }

}

.button-secondaryGreen {
    background-color: transparent;
    border: none;
    color: $primaryColor-Green;
    @include fontSize;

}

.button-red {
    background-color: transparent;
    border: none;
    color: $delete-red;
    @include fontSize;

}

.button-black {
    background-color: transparent !important;
    border: none !important;
    color: $black !important;
}

.button-white {
    background-color: transparent !important;
    border: none !important;
    color: $white !important;
}

.button-transparent-arrow {
    background-color: transparent;
    border: none;
    color: $primaryColor-Green;
    @include fontSize;

    &:hover {
        background-color: transparent !important;
        color: $primaryColor-Green;
    }

    &:active {
        background-color: transparent !important;
        color: $primaryColor-Green;
    }

    &:focus-visible {
        outline: 4px solid $accent-green !important;
    }

}

.button-green {
    background-color: $primaryColor-Green;
    color: $white;
    @include fontSize;


    &:hover {
        background-color: $accent-green !important;
        color: $green-dark !important;
    }

    &:active {
        background-color: $accent-green !important;
        color: $green-dark !important;
    }

    &:focus-visible {
        outline: 4px solid $accent-green !important;
    }
}


.ant-popconfirm-buttons {
    .ant-btn-default {
        background-color: $white;
        color: $primaryColor-Green;

        &:hover {
            background-color: $green-dark !important;
            color: $white !important;
            border: $green-dark !important;
        }

    }

    .ant-btn-primary {
        background-color: $primaryColor-Green;

        &:hover {
            background-color: $white !important;
            color: $green-dark !important;
        }
    }
}