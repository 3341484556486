.ant-table-content {
    .ant-table-thead {
        tr {
            .ant-table-cell {
                background: $accent-green;
                border: 1px solid $green-dark;
                color: $primaryColor-Green;
                font-weight: bold;
            }

            .ant-table-cell::before {
                background-color: transparent !important;
            }
        }
    }

    .ant-table-tbody {
        tr {
            .ant-table-cell {
                border: 1px solid $green-dark;
                color: $primaryColor-Green;
                font-weight: bold;
            }
        }
    }
}