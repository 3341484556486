.card-color {
    border-radius: 5px;
    height: 8.5rem;
    width: 8.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover {
        border-color: $green-dark !important;
    }

    &__name {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 32px;
        line-height: 25px;
        text-align: center;
        color: $white;
        margin-bottom: -10px;
        font-weight: bold;
    }

    &__value {
        font-family: 'Poppins';
        font-weight: 700;
        font-size: 66px;
        line-height: 25px;
        text-align: center;
        color: $white;
        font-weight: bold;
    }
}

.green {
    background-color: $green-card;
}

.red {
    background-color: $red-card;
}

.yellow {
    background-color: $yellow-card;
}

.grey {
    display: flex;
    flex-direction: column;
    background-color: #c9c9c9;

    p {
        position: absolute;
    }
}

@include for-tablet-portrait-up {
    .card-color {

        height: 8rem;
        width: 8rem;

        &__name {
            font-weight: 700;
            font-size: 15px;
        }

        &__value {
            font-size: 25px;
        }
    }
}


@include for-phone-small-only {
    .card-color {
        height: 4rem;
        width: 4rem;

        &__name {
            font-weight: 700;
            font-size: 12px;
        }

        &__value {
            font-size: 20px;
        }
    }
}

@include for-desktop-mac-up {
    .card-color {
        height: 6rem;
        width: 6rem;

        &__name {
            font-weight: 700;
            font-size: 18px;
        }

        &__value {
            font-size: 30px;
        }
    }
}
@include for-tablet {

    .card-color {
        width: 4.5rem;
        height: 4.5rem;
    }

}


@include for-1440 {
    .card-color {
        width: 5.5rem;
        height: 5.5rem;
    }
}

@include for-1366 {
    .card-color {
        width: 7rem;
        height: 7rem;
    }
}

@include for-tablet-portrait-up {
    .card-color {
        margin-top: 5rem;
        width: 7rem;
        height: 7rem;
    }
}

@include for-14 {
    .card-color {
        margin-top: 0px !important;
        width: 5rem;
        height: 5rem;
    }
}

@include for-15-pulgadas {
    .card-color {
        width: 6rem !important;
        height: 6rem !important;
    }
}

@include for-1920 {
    .card-color {
        margin-top: 0px !important;
        width: 5.5rem;
        height: 5.5rem;
    }
}

@include for-phone-only {
    .card-color {
        margin-top: 2rem !important;
    }
}