.slick-slide>div {
    display: flex;
    justify-content: center !important;
    gap: 1rem;
    flex: 1 1;
    height: 100%;
    margin-bottom: 1rem;
}

.slick-slide>div>div {
    display: flex !important;
}


.slick-grid {
    margin-top: 2rem;
    margin-left: 2.5rem;
    margin-right: 2rem;
}

.slick-cloned {
    display: none !important;
}

.slick-list {
    z-index: 100;
}

.slick-slider,
.slick-list,
.slick-track {
    height: 100% !important;
}

.slick-prev:before,
.slick-next:before {
    font-family: 'slick';
    font-size: 20px !important;
    line-height: 1;
    opacity: .75;
    color: $primaryColor-Green !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.custom-arrow {
    z-index: 1000 !important;
    top: 30% !important;
}

@include for-desktop-mac-up {
    .slick-track {
        margin-top: 0px;
    }

    .slick-slider {
        margin-top: 0px;
    }
}