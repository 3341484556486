.card-login {
    border-radius: 1rem !important;
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 20rem;

    .ant-card-body {
        margin: auto;
        display: flex;
        justify-content: center;
        width: 20rem;

    }
}
