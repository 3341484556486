.card-body {
    flex: 2;
    margin-top: 2rem !important;
    // border: 2px solid green;
}


.card-footer {
    display: flex;
    gap: 1rem;
    height: 3rem;
    z-index: 10;
    margin: auto;

    button {
        background-color: transparent;
        border: none;
        box-shadow: none !important;
        width: 20px !important;
        padding: 0;
        font-size: 25px;

        &:disabled {
            background-color: transparent !important;
        }
    }
}

