@include for-phone-small-only {
    .container-card {
        width: 13rem !important;
    }

    .container-carousel {
        width: 100% !important;
    }

    .custom-arrow {
        z-index: 1000 !important;
        top: 10% !important;
    }
}