* {
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;

}

html,
body {
  width: 100%;
  height: 100%;
  background: whitesmoke;

}

#root {
  height: 100% !important;
  width: 100% !important;
}

.flex {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: auto;
}