.form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100% !important;

    &__item {
        width: 100%;
    }

    &__copy {
        display: flex;
        align-items: center;
        gap: .2rem;
    }

    &__icon-copy {
        width: 1rem;
    }
}

.select-module {
    width: 10rem !important;
    margin-bottom: 1rem;
}