.menu-mobile {
    &__container {
        display: flex;
        align-items: center;
        width: 90%;
        margin-left: 5%;
        color: $white;
        gap: 1rem;
        justify-content: space-between;
    }

    &__user {
        display: flex;
        gap: 2rem;
    }

    &__icon {
        display: flex;
        align-items: center;
        margin-top: 2rem !important;

        img {
            width: 200px;
        }
    }

    &__menu {
        display: flex;
        align-items: center;
    }
}