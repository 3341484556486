.ant-col {
    margin-left: 0;
}

.ant-card-bordered {
    border-radius: .5rem !important;
    border-color: transparent;
    -webkit-box-shadow: 3px 3px 5px 0px rgba(112, 112, 112, 0.67);
    -moz-box-shadow: 3px 3px 5px 0px rgba(112, 112, 112, 0.67);
    box-shadow: 3px 3px 5px 0px rgba(112, 112, 112, 0.67);
    border: none !important;

    ;
}

.ant-card-body {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    padding: 10px !important;

    img {
        width: 4rem;
    }
}

.ant-progress-status-success .ant-progress-text {
    color: $black !important;
    font-size: 15px;
    font-weight: bold;
}

.progress-format-style {
    display: flex;
    flex-direction: row;
    height: 4rem;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    padding-top: 1rem;
}

.ant-progress-text {
    color: $black !important;
    font-size: 20px;
    font-weight: bold;
    width: max-content;
    // margin-top: -.5rem !important;
    overflow-wrap: break-word;
    hyphens: manual;

}

.ant-progress-text-units {
    color: $black !important;
    font-size: 20px;
    font-weight: bold;
}

.ant-progress-text-notValue {
    color: $black !important;
    font-size: 15px;
    font-weight: bold;
    height: 2rem;

}

.ant-progress-inner {
    width: 6rem !important;
    height: 6rem !important;
}

.ant-progress-inner:hover {
    cursor: pointer !important;
}

.ant-card-head-title {
    font-size: 25px;
}

@include for-desktop-up {

    .ant-progress-text {
        font-size: 20px !important;
        padding-top: 2rem !important;

    }

    .ant-progress-inner {
        width: 120px !important;
        height: 120px !important;
    }


}

@include for-tablet-portrait-up {

    .ant-progress-inner {
        width: 120px !important;
        height: 120px !important;
    }
}

@include for-phone-small-only {
    .ant-progress-inner {
        width: 120px !important;
        height: 120px !important;
    }
}

@include for-desktop-mac-up {
    .ant-progress-text {
        height: 3rem;
        padding-top: 2rem !important;
    }

    .ant-progress-text-notValue {
        height: 4.5rem !important;
        margin-top: -3rem !important;
    }
}